<template>
  <v-dialog v-model="visibility" width="1160px">
    <error-snackbar
      v-if="showErrorSnackbar"
      :snackbarText="errorSnackbarText"
    />
    <v-card style="background: #fafafa; padding: 32px 12px">
      <v-row no-gutters style="padding: 0px 20px">
        <h3
          style="
            color: #1b1b1b;
            font-family: 'MacPaw Fixel';
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
          "
        >
          Наші фото
        </h3>
        <v-row no-gutters justify="end">
          <img
            src="@/assets/img/iconsSvg/closeIcon.svg"
            style="cursor: pointer"
            @click="$emit('close')"
            alt="close"
          />
        </v-row>
      </v-row>
      <div style="padding: 0px 20px">
        <div class="sectionLine" style="margin: 20px 0px"></div>
      </div>
      <div style="overflow-y: scroll; height: 70svh">
        <loader v-if="showLoader" />
        <v-row v-else no-gutters>
          <v-col
            class="px-0 py-0"
            cols="3"
            v-for="image in images"
            :key="image.id"
          >
            <image-item
              :image="image"
              @chooseImage="chooseImage"
              @deleteImage="deleteImage"
              style="cursor: pointer; user-select: none"
            />
          </v-col>
        </v-row>
        <v-row
          no-gutters
          justify="center"
          style="margin-top: 12px"
          v-if="paginationLength > 1"
        >
          <pagination :page="page" :quantityPage="paginationLength" @changePage="changePage" />
        </v-row>
      </div>
      <submit-button
        style="height: 48px !important"
        @click="$refs.upload_img.click()"
        ><div class="plusIcon" />
        Додати фото</submit-button
      >
    </v-card>
    <input
      type="file"
      ref="upload_img"
      style="display: none"
      multiple
      @change="uploadImg"
    />
  </v-dialog>
</template>

<script>
import modalMixin from "../../mixins/modalMixin";
import submitButton from "./Buttons/submitButton.vue";
import ImageItem from "./imageItem.vue";
import imageService from "./../../requests/Admin/imageService.js";
import Loader from "./Loader.vue";
import ErrorSnackbar from "./Snackbars/errorSnackbar.vue";
import Pagination from "./pagination.vue";
export default {
  components: { submitButton, ImageItem, Loader, ErrorSnackbar, Pagination },
  mixins: [modalMixin],
  data: () => ({
    images: [],
    page: 1,
    paginationLength: 0,
    showLoader: true,
    showErrorSnackbar: false,
    errorSnackbarText: "",
    isHover: false,
    maxImageSize: 2097152,
    getImagesTimeout: "",
  }),
  props: {
    type: {
      require: true,
    },
  },
  mounted() {
    this.getImages();
  },
  methods: {
    changePage(page){
      this.page = page;
      this.showLoader = true;
      this.getImages();
    },
    async getImages() {
      let response = await imageService.getImages(this.type, this.page);
      this.images = response.data;
      this.paginationLength = response?.pagination?.meta?.last_page;
      this.showLoader = false;
    },
    async uploadImg(e) {
      this.errorSnackbarText = "";
      this.showErrorSnackbar = false;
      this.showLoader = true;
      await Promise.all(
        Object.values(e.srcElement.files).map(async (file) => {
          if (file.size < parseInt(this.maxImageSize)) {
            let image = new FormData();
            image.append("name", file.name);
            image.append("type", this.type);
            image.append("image", file);
            await imageService.uploadImage(image);
          } else {
            this.showErrorSnackbar = true;
            this.errorSnackbarText = `Розмір зображення повинен не перевищувати ${
              this.maxImageSize / 1024 / 1024
            } МБ`;
            this.showLoader = false;
          }
        })
      );
      await this.getImages();
    },
    async deleteImage(id) {
      await imageService.deleteImage(id).then(() => {
        this.showLoader = true;
        this.getImages();
      });
    },
    chooseImage(image) {
      this.$emit("chooseImage", image);
    },
  },
};
</script>

<style>
</style>