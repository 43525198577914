import requestService from "../requestService";
import store from '@/store';

export default {
    async createScheme(form) {
        const response = await requestService.post(`/cp_dash/scheme`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async updateScheme(id, form) {
        const response = await requestService.post(`/cp_dash/scheme/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getSchemes() {
        const response = await requestService.get(`/cp_dash/scheme`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getScheme(id) {
        const response = await requestService.get(`/cp_dash/scheme/${id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async deleteScheme(id) {
        const response = await requestService.delete(`/cp_dash/scheme/${id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
}