<template>
  <div class="imageCard">
    <v-row no-gutters align="center">
      <v-col cols="10" class="px-0 py-0"
        ><div
          style="
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
          "
        >
          {{ image?.name }}
        </div></v-col
      >
      <v-col>
        <v-row no-gutters justify="end">
          <v-menu offset-y nudge-left="70px">
            <template v-slot:activator="{ on, attrs }">
              <div class="iconBackground" v-bind="attrs" v-on="on">
                <div class="dotsVerticalIcon" />
              </div>
            </template>
            <div
              style="
                border-radius: 5px;
                border: 1px solid rgba(181, 181, 181, 0.3);
                background: #fcfcfc;
                width: 180px;
                padding: 20px;
                color: #515151;
                text-align: center;
                font-size: 14px;
                font-weight: 400;
              "
            >
              Опції
              <div style="border-bottom: 1px solid #f2f2f0; margin-top: 5px" />
              <v-col class="px-0 py-0" style="text-align: left">
                <p
                  style="
                    margin-top: 14px;
                    margin-bottom: 16px;
                    cursor: pointer;
                    user-select: none;
                  "
                  @click="$emit('chooseImage', image)"
                >
                  Вибрати
                </p>
                <p
                  style="cursor: pointer; user-select: none; color: red"
                  @click="$emit('deleteImage', image?.id)"
                >
                  Видалити
                </p>
              </v-col>
            </div>
          </v-menu>
        </v-row>
      </v-col>
    </v-row>
    <img
      @click="$emit('chooseImage', image)"
      :src="image?.path"
      alt="bus img"
      width="100%"
      height="195px"
      style="margin-top: 8px; border-radius: 10px; object-fit: cover"
    />
  </div>
</template>

<script>
export default {
  props: {
    image: {
      require: false,
    },
  },
};
</script>

<style scoped>
.imageCard {
  border-radius: 10px;
  background: #fff;
  padding: 12px;
  margin: 10px;
}
</style>