<template>
  <v-dialog v-model="visibility" width="600px">
    <div style="padding: 20px; background-color: #fff">
      <v-row
        no-gutters
        style="border-bottom: 1px solid #e5e9eb; padding-bottom: 20px"
      >
        <span
          style="
            color: #1b1b1b;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
          "
          >{{ "Зміна номеру" }}</span
        >
        <v-row no-gutters justify="end">
          <v-icon color="#1B1B1B" @click="$emit('close')" size="32px"
            >mdi-close</v-icon
          >
        </v-row>
      </v-row>
      <v-col class="px-0 py-0">
        <v-text-field
          outlined
          dense
          height="50px"
          style="border-radius: 10px; margin-top: 32px"
          color="#C0C0C0"
          background-color="#FCFCFC"
          label="Назва схеми"
          placeholder="Введіть назву.."
          v-model="scheme.number"
          @input="numbersError = []"
          :error-messages="numbersError.length > 0 ? numbersError : numberError"
        />
      </v-col>
      <submit-button
        width="100%"
        style="height: 48px !important; font-size: 20px"
        :disabled="$v.$invalid"
        @click="saveScheme"
        >Зберегти
      </submit-button>
    </div>
  </v-dialog>
</template>
  
  <script>
import { validationMixin } from "vuelidate";
import modalMixin from "@/mixins/modalMixin";
import { required } from "vuelidate/lib/validators";
import submitButton from "../../../UI/Buttons/submitButton.vue";

export default {
  components: { submitButton },
  mixins: [modalMixin, validationMixin],
  data: () => ({
    scheme: {},
    numbersError: [],
  }),
  props: {
    seatForEdit: {
      require: true,
    },
    seats: {
      require: true,
    },
  },
  mounted() {
    this.scheme = { ...this.seatForEdit };
  },
  validations: {
    scheme: {
      number: {
        required,
      },
    },
  },
  methods: {
    saveScheme() {
      this.$v.$touch();
      if (
        this.seats
          .flat()
          .findIndex((seat) => seat.number == this.scheme.number) !== -1
      ) {
        this.numbersError.push("Місце з таким номером вже існує");
        return;
      }
      if (!this.$v.$invalid) {
        this.$emit("confirmSave", this.scheme);
      }
    },
  },
  computed: {
    numberError() {
      const errors = [];
      let field = this.$v.scheme.number;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
  },
};
</script>
  
  <style>
</style>