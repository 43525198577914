<template>
  <div class="sectionBackground">
    <p class="sectionTitle">Багатомовність</p>
    <p class="sectionText">Дадавайте контент на різних мовах</p>
    <div class="sectionLine" style="margin: 20px 0px" />
    <v-row no-gutters align="center">
      <div
        class="statusBox"
        :class="activeLang == 'ua' ? 'activeStatusBox' : ''"
        @click="activeLang = 'ua'"
      >
        Українська
      </div>
      <div
        class="statusBox"
        :class="activeLang == 'en' ? 'activeStatusBox' : ''"
        @click="activeLang = 'en'"
      >
        English
      </div>
      <div
        class="statusBox"
        :class="activeLang == 'pl' ? 'activeStatusBox' : ''"
        @click="activeLang = 'pl'"
      >
        Polish
      </div>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    activeLang: "ua",
  }),
  watch: {
    activeLang: {
      handler() {
        this.$emit("setLang", this.activeLang);
      },
    },
  },
};
</script>

<style>
</style>