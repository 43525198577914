<template>
  <v-col class="px-0 py-0">
    <backBtn
      label="Назад"
      @click="$router.push({ name: 'autoparkComponent' })"
    ></backBtn>
    <changeLangForm @setLang="setLang"></changeLangForm>
    <div class="sectionBackground" style="margin-top: 20px">
      <p class="sectionTitle">Основні дані</p>
      <div
        class="sectionLine"
        style="margin-top: 12px; margin-bottom: 20px"
      ></div>
      <v-row no-gutters align="center">
        <v-col class="py-0" style="padding-right: 10px">
          <v-row no-gutters>
            <v-col class="px-0 py-0" cols="6">
              <v-text-field
                outlined
                dense
                style="border-radius: 10px; padding-right: 10px"
                color="#E2E2E2"
                background-color="#FFF"
                label="Марка"
                placeholder="Введіть тут.."
                height="48px"
                v-model="bus.manufacturer"
                :error-messages="manufacturerError"
            /></v-col>
            <v-col class="px-0 py-0" cols="6">
              <v-text-field
                outlined
                dense
                style="border-radius: 10px; padding-left: 10px"
                color="#E2E2E2"
                background-color="#FFF"
                label="Модель"
                placeholder="Введіть тут.."
                height="48px"
                v-model="bus.model"
                :error-messages="modelError"
            /></v-col>
            <v-col class="px-0 py-0" cols="6">
              <v-text-field
                outlined
                dense
                style="border-radius: 10px; padding-right: 10px"
                color="#E2E2E2"
                background-color="#FFF"
                label="Рік випуску"
                placeholder="Введіть тут.."
                height="48px"
                v-model="bus.year"
                :error-messages="yearError"
            /></v-col>
            <v-col class="px-0 py-0" cols="6">
              <v-text-field
                outlined
                dense
                style="border-radius: 10px; padding-left: 10px"
                color="#E2E2E2"
                background-color="#FFF"
                label="Колір"
                placeholder="Введіть тут.."
                height="48px"
                v-model="colorModel"
                :error-messages="colorError"
            /></v-col>
            <v-col class="px-0 py-0" cols="6">
              <v-text-field
                outlined
                dense
                style="border-radius: 10px; padding-right: 10px"
                color="#E2E2E2"
                background-color="#FFF"
                label="Кількість місць"
                placeholder="Введіть тут.."
                height="48px"
                v-model="bus.seats"
                :error-messages="seatsError"
            /></v-col>
            <v-col class="px-0 py-0" cols="6">
              <v-text-field
                outlined
                dense
                style="border-radius: 10px; padding-left: 10px"
                color="#E2E2E2"
                background-color="#FFF"
                label="Поверховість автобуса"
                placeholder="Введіть тут.."
                height="48px"
                v-model="floorModel"
                :error-messages="floorError"
            /></v-col>
            <v-col class="px-0 py-0" cols="12">
              <v-text-field
                outlined
                dense
                style="border-radius: 10px"
                color="#E2E2E2"
                background-color="#FFF"
                label="Номер автобуса"
                placeholder="Введіть тут.."
                height="48px"
                v-model="bus.government_number"
                :error-messages="govermentNumberError"
            /></v-col>
          </v-row>
        </v-col>
        <v-col class="py-0" style="padding-left: 10px">
          <v-textarea
            outlined
            dense
            style="border-radius: 10px"
            color="#E2E2E2"
            background-color="#FFF"
            label="Опис"
            placeholder="Введіть тут.."
            height="270px"
            v-model="descriptionBus"
            :error-messages="descriptionError"
          />
        </v-col>
      </v-row>
    </div>
    <div class="sectionBackground" style="margin-top: 20px">
      <p class="sectionTitle">Салон автобуса</p>
      <p class="sectionText">Схема посадкових місць в автобусі</p>
      <div
        class="sectionLine"
        style="margin-top: 12px; margin-bottom: 20px"
      ></div>
      <v-col cols="5">
        <v-autocomplete
          outlined
          dense
          hide-details
          style="border-radius: 10px"
          label="Шаблон"
          placeholder="Виберіть шаблон"
          height="48px"
          v-model="scheme"
          :items="Object.values(schemesList)"
          :item-text="'name'"
          :item-value="'id'"
        />
      </v-col>
      <v-expand-transition>
        <v-col
          style="text-align: center; margin: 40px 0px"
          v-if="!isShowBusScheme"
        >
          <p
            style="
              color: #1b1b1b;
              text-align: center;
              font-family: 'MacPaw Fixel';
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              margin-bottom: 10px;
            "
          >
            Шаблон не вибрано
          </p>
          <p
            style="
              color: #4b5262;
              font-family: 'MacPaw Fixel';
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-bottom: 20px;
            "
          >
            Створіть або виберіть схему салону автобуса
          </p>
          <v-row no-gutters justify="center">
            <submit-button
              style="width: 220px; height: 48px"
              @click="
                (isShowBusScheme = true), (isNewScheme = true), (scheme = '')
              "
              >Новий шаблон</submit-button
            >
          </v-row>
        </v-col>
      </v-expand-transition>
      <v-expand-transition>
        <seats-constructor
          v-if="isShowBusScheme"
          :choosedScheme="choosedScheme"
          :busScheme="bus.seats_scheme"
          :isNewScheme="isNewScheme"
          @setSeatsScheme="setSeatsScheme"
          @successCreateScheme="getSchemes"
          @cancel="(isShowBusScheme = false), (isNewScheme = false)"
        />
      </v-expand-transition>

      <div
        style="
          color: #df3a3a;
          font-family: 'MacPaw Fixel';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 20px;
        "
        v-if="showSeatSchemeError"
      >
        * Виберіть або створіть шаблон салона автобуса
      </div>
    </div>
    <div class="sectionBackground" style="margin-top: 20px">
      <p class="sectionTitle">Додатковий сервіс</p>
      <p class="sectionText">
        Додайте інформацію про зручності, які доступні на вашому автобусі
      </p>
      <div
        class="sectionLine"
        style="margin-top: 12px; margin-bottom: 20px"
      ></div>
      <v-row no-gutters align="center">
        <div
          class="optionBox"
          :class="choosedServices.includes(service.id) ? 'activeOptionBox' : ''"
          v-for="service in servicesList"
          :key="service.id"
          @click="chooseService(service.id)"
        >
          {{
            service.translations.find((service) => service.lang == "ua")?.name
          }}
        </div>
      </v-row>
      <div
        style="
          color: #df3a3a;
          font-family: 'MacPaw Fixel';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 20px;
        "
        v-if="showServiceError"
      >
        * Потрібно вибрати хоча б один сервіс
      </div>
      <add-new-item-btn
        text="Створити новий сервіс"
        style="margin-top: 40px"
        @click="showNewServiceModal = true"
      />
    </div>
    <div class="sectionBackground" style="margin-top: 20px">
      <p class="sectionTitle">Фото</p>
      <div
        class="sectionLine"
        style="margin-top: 12px; margin-bottom: 20px"
      ></div>
      <v-row no-gutters align="center" :key="key">
        <item-image
          v-for="(image, index) in bus.images"
          :key="image.id"
          @click="(busImageIndex = index), (showImageGallery = true)"
          @deleteImg="deleteImg"
          :image="image"
          :index="index"
        />
      </v-row>
      <div
        style="
          color: #df3a3a;
          font-family: 'MacPaw Fixel';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 20px;
        "
        v-if="showImageError"
      >
        * Потрібно додати хоча б одне фото
      </div>
    </div>
    <submit-button
      style="
        font-size: 20px;
        font-weight: 700;
        margin-top: 40px;
        height: 48px !important;
      "
      @click="isEdit ? updateBus() : createBus()"
      >{{ isEdit ? "Оновити" : "Створити" }}
    </submit-button>
    <create-new-service-modal
      v-if="showNewServiceModal"
      :visible="showNewServiceModal"
      :order="servicesList.length"
      @successCreateService="(showNewServiceModal = false), getServices()"
      @close="showNewServiceModal = false"
    />
    <image-gallery-modal
      v-if="showImageGallery"
      :visible="showImageGallery"
      type="Bus"
      @chooseImage="setBusImage"
      @close="showImageGallery = false"
    />
  </v-col>
</template>

<script>
import { validationMixin } from "vuelidate";
import AddNewItemBtn from "../../UI/Buttons/addNewItemBtn.vue";
import submitButton from "../../UI/Buttons/submitButton.vue";
import CreateNewServiceModal from "./createNewServiceModal.vue";
import { required } from "vuelidate/lib/validators";
import backBtn from "./../../UI/Buttons/backBtn.vue";
import changeLangForm from "./../../UI/Forms/changeLangForm.vue";
import requestFormData from "./../../../requests/requestFormData.js";
import busService from "./../../../requests/Bus/busService.js";
import busServicesService from "../../../requests/Bus/busServicesService";
import ImageGalleryModal from "../../UI/imageGalleryModal.vue";
import ItemImage from "../../UI/Forms/itemImage.vue";
import SeatsConstructor from "./seatsConstructor/seatsConstructor.vue";
import busSchemeService from "../../../requests/Bus/busSchemeService.js";
export default {
  components: {
    submitButton,
    CreateNewServiceModal,
    AddNewItemBtn,
    backBtn,
    changeLangForm,
    ImageGalleryModal,
    ItemImage,
    SeatsConstructor,
  },
  mixins: [validationMixin],
  data: () => ({
    bus: {
      images: [
        {
          id: 1,
          path: "",
          file: {},
        },
        {
          id: 2,
          path: "",
          file: {},
        },
        {
          id: 3,
          path: "",
          file: {},
        },
      ],
      seats_scheme: [],
      description: "",
      description_en: "",
      description_pl: "",
      color: "",
      color_en: "",
      color_pl: "",
      floor: "",
      floor_en: "",
      floor_pl: "",
    },
    choosedServices: [],
    servicesList: [],
    schemesList: [],
    showNewServiceModal: false,
    showImageGallery: false,
    busImageIndex: null,
    showImageError: false,
    showServiceError: false,
    showSeatSchemeError: false,
    key: 0,
    activeLang: "ua",
    isEdit: false,
    isShowBusScheme: false,
    isNewScheme: false,
    choosedScheme: null,
    scheme: "",
  }),
  validations: {
    bus: {
      manufacturer: {
        required,
      },
      model: {
        required,
      },
      year: {
        required,
      },
      color: {
        required,
      },
      seats: {
        required,
      },
      floor: {
        required,
      },
      government_number: {
        required,
      },
      description: {
        required,
      },
    },
  },
  mounted() {
    if (this.$route.name == "editAutopark") {
      this.isEdit = true;
      this.getBusById();
    }
    this.getServices();
    this.getSchemes();
  },
  methods: {
    setLang(lang) {
      this.activeLang = lang;
    },
    setSeatsScheme(scheme) {
      this.bus.seats_scheme = scheme;
      this.showSeatSchemeError = false;
    },
    async getServices() {
      await busServicesService.getServices().then((res) => {
        if (res.status == "Success") {
          this.servicesList = res.data;
        }
      });
    },
    async getBusById() {
      await busService.getBus(this.$route.params.id).then((res) => {
        if (res.status == "Success") {
          this.setBusForEdit(res.data);
        }
      });
    },
    async getSchemes() {
      await busSchemeService.getSchemes().then((res) => {
        if (res.status == "Success") {
          this.schemesList = res.data;
        }
      });
    },
    async createBus() {
      this.$v.$touch();
      if (this.choosedServices.length < 1) {
        this.showServiceError = true;
      }
      if (this.bus.images.findIndex((image) => image.path) == -1) {
        this.showImageError = true;
      }
      if (this.bus.seats_scheme.length < 1) {
        this.showSeatSchemeError = true;
        return;
      }
      if (!this.$v.$invalid) {
        let images = [];
        this.bus.images.forEach((image) => {
          if (image.path) {
            images.push(image.id);
          }
        });
        let data = {
          manufacturer: this.bus.manufacturer,
          model: this.bus.model,
          year: this.bus.year,
          seats: this.bus.seats,
          seats_scheme: JSON.stringify(this.bus.seats_scheme),
          government_number: this.bus.government_number,
          color: this.bus.color,
          status: "Active",
          translations: [
            {
              lang: "ua",
              slug: this.slugify(`${this.bus.manufacturer} ${this.bus.model}`),
              description: this.bus.description,
              color: this.bus.color,
              floors: this.bus.floor,
            },
            {
              lang: "en",
              slug: this.slugify(`${this.bus.manufacturer} ${this.bus.model}`),
              description:
                this.bus.description_en !== ""
                  ? this.bus.description_en
                  : this.bus.description,
              floors:
                this.bus.floor_en !== "" ? this.bus.floor_en : this.bus.floor,
              color:
                this.bus.color_en !== "" ? this.bus.color_en : this.bus.color,
            },
            {
              lang: "pl",
              slug: this.slugify(`${this.bus.manufacturer} ${this.bus.model}`),
              description:
                this.bus.description_pl !== ""
                  ? this.bus.description_pl
                  : this.bus.description,
              floors:
                this.bus.floor_pl !== "" ? this.bus.floor_pl : this.bus.floor,
              color:
                this.bus.color_pl !== "" ? this.bus.color_pl : this.bus.color,
            },
          ],
          images: images,
          services: this.choosedServices,
        };
        let form = requestFormData.jsonToFormData(data);
        await busService.createBus(form).then((res) => {
          if (res.status == "Success") {
            this.$router.push({ name: "autoparkComponent" });
          }
        });
      }
    },
    async updateBus() {
      this.$v.$touch();
      if (this.choosedServices.length < 1) {
        this.showServiceError = true;
      }
      if (this.bus.images.findIndex((image) => image.path) == -1) {
        this.showImageError = true;
      }
      if (this.bus.seats_scheme.length < 1) {
        this.showSeatSchemeError = true;
        return;
      }
      if (!this.$v.$invalid) {
        let images = [];
        this.bus.images.forEach((image) => {
          if (image.path) {
            images.push(image.id);
          }
        });
        let data = {
          manufacturer: this.bus.manufacturer,
          model: this.bus.model,
          year: this.bus.year,
          seats: this.bus.seats,
          seats_scheme: JSON.stringify(this.bus.seats_scheme),
          government_number: this.bus.government_number,
          color: this.bus.color,
          status: "Active",
          translations: [
            {
              id: this.bus.translate_id,
              lang: "ua",
              slug: this.bus.slug,
              description: this.bus.description,
              color: this.bus.color,
              floors: this.bus.floor,
            },
            {
              id: this.bus.translate_id_en,
              lang: "en",
              slug: this.bus.slug_en,
              description:
                this.bus.description_en !== ""
                  ? this.bus.description_en
                  : this.bus.description,
              floors:
                this.bus.floor_en !== "" ? this.bus.floor_en : this.bus.floor,
              color:
                this.bus.color_en !== "" ? this.bus.color_en : this.bus.color,
            },
            {
              id: this.bus.translate_id_pl,
              lang: "pl",
              slug: this.bus.slug_pl,
              description:
                this.bus.description_pl !== ""
                  ? this.bus.description_pl
                  : this.bus.description,
              floors:
                this.bus.floor_pl !== "" ? this.bus.floor_pl : this.bus.floor,
              color:
                this.bus.color_pl !== "" ? this.bus.color_pl : this.bus.color,
            },
          ],
          images: images,
          services: this.choosedServices,
        };
        let form = requestFormData.jsonToFormData(data);
        await busService.updateBus(this.bus.id, form).then((res) => {
          if (res.status == "Success") {
            this.$router.push({ name: "autoparkComponent" });
          }
        });
      }
    },
    chooseService(id) {
      if (!this.choosedServices.includes(id)) {
        this.choosedServices.push(id);
      } else {
        let index = this.choosedServices.findIndex((item) => item == id);
        this.choosedServices.splice(index, 1);
      }
      this.showServiceError = false;
    },
    setBusImage(image) {
      let isExist = this.bus.images.findIndex(
        (image_) => image_.id == image.id
      );
      if (isExist == -1) {
        this.bus.images[this.busImageIndex] = image;
        this.showImageGallery = false;
      } else {
        this.showImageGallery = false;
        this.showErrorModal = true;
      }
      if (this.bus.images.findIndex((image) => image.path == "") == -1) {
        this.bus.images.push({
          id: new Date().getTime(),
          path: "",
          file: {},
        });
      }
      this.showImageError = false;
    },
    setBusForEdit(item) {
      let translate = item.translations.find(
        (translate) => translate.lang == "ua"
      );
      let translate_en = item.translations.find(
        (translate) => translate.lang == "en"
      );
      let translate_pl = item.translations.find(
        (translate) => translate.lang == "pl"
      );
      this.$set(this.bus, "id", item.id);
      this.$set(this.bus, "manufacturer", item.manufacturer);
      this.$set(this.bus, "model", item.model);
      this.$set(this.bus, "year", item.year);
      this.$set(this.bus, "seats", item.seats);
      this.$set(this.bus, "seats_scheme", JSON.parse(item.seats_scheme));
      this.$set(this.bus, "government_number", item.government_number);
      this.$set(this.bus, "images", item.images);
      this.$set(this.bus, "translate_id", translate.id);
      this.$set(this.bus, "translate_id_en", translate_en.id);
      this.$set(this.bus, "translate_id_pl", translate_pl.id);
      this.$set(this.bus, "slug", translate.slug);
      this.$set(this.bus, "slug_en", translate_en.slug);
      this.$set(this.bus, "slug_pl", translate_pl.slug);
      this.$set(this.bus, "color", translate.color);
      this.$set(this.bus, "color_en", translate_en.color);
      this.$set(this.bus, "color_pl", translate_pl.color);
      this.$set(this.bus, "floor", translate.floors);
      this.$set(this.bus, "floor_en", translate_en.floors);
      this.$set(this.bus, "floor_pl", translate_pl.floors);
      this.$set(this.bus, "description", translate.description);
      this.$set(this.bus, "description_en", translate_en.description);
      this.$set(this.bus, "description_pl", translate_pl.description);
      this.isShowBusScheme = true;
      if (this.bus.seats_scheme == null) {
        this.isNewScheme = true;
      }
      item.services.forEach((service) => this.choosedServices.push(service.id));
    },
    deleteImg(id) {
      const index = this.bus.images.findIndex((image) => image.id == id);
      this.bus.images[index] = {
        id: new Date().getTime(),
        path: "",
        file: {},
      };
      this.key++;
    },
    slugify(text, ampersand = "and") {
      const a = "àáäâèéëêìíïîòóöôùúüûñçßÿỳýœæŕśńṕẃǵǹḿǘẍźḧ";
      const b = "aaaaeeeeiiiioooouuuuncsyyyoarsnpwgnmuxzh";
      const p = new RegExp(a.split("").join("|"), "g");

      return text
        .toString()
        .toLowerCase()
        .replace(/[\s_]+/g, "-")
        .replace(p, (c) => b.charAt(a.indexOf(c)))
        .replace(/&/g, `-${ampersand}-`)
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "-")
        .replace(/^-+|-+$/g, "");
    },
  },
  watch: {
    scheme: {
      deep: true,
      handler() {
        this.choosedScheme = this.schemesList.find(
          (scheme) => scheme.id == this.scheme
        ).scheme;
        console.log(this.choosedScheme);
        this.isShowBusScheme = true;
      },
    },
  },
  computed: {
    descriptionBus: {
      get() {
        switch (this.activeLang) {
          case "ua": {
            return this.bus.description;
          }
          case "en": {
            return this.bus.description_en;
          }
          case "pl": {
            return this.bus.description_pl;
          }
        }
        return this.bus.description;
      },
      set(val) {
        switch (this.activeLang) {
          case "ua":
            {
              this.bus.description = val;
            }
            break;
          case "en":
            {
              this.bus.description_en = val;
            }
            break;
          case "pl":
            {
              this.bus.description_pl = val;
            }
            break;
        }
      },
    },
    colorModel: {
      get() {
        switch (this.activeLang) {
          case "ua": {
            return this.bus.color;
          }
          case "en": {
            return this.bus.color_en;
          }
          case "pl": {
            return this.bus.color_pl;
          }
        }
        return this.bus.color;
      },
      set(val) {
        switch (this.activeLang) {
          case "ua":
            {
              this.bus.color = val;
            }
            break;
          case "en":
            {
              this.bus.color_en = val;
            }
            break;
          case "pl":
            {
              this.bus.color_pl = val;
            }
            break;
        }
      },
    },
    floorModel: {
      get() {
        switch (this.activeLang) {
          case "ua": {
            return this.bus.floor;
          }
          case "en": {
            return this.bus.floor_en;
          }
          case "pl": {
            return this.bus.floor_pl;
          }
        }
        return this.bus.floor;
      },
      set(val) {
        switch (this.activeLang) {
          case "ua":
            {
              this.bus.floor = val;
            }
            break;
          case "en":
            {
              this.bus.floor_en = val;
            }
            break;
          case "pl":
            {
              this.bus.floor_pl = val;
            }
            break;
        }
      },
    },
    manufacturerError() {
      const errors = [];
      let field = this.$v.bus.manufacturer;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    modelError() {
      const errors = [];
      let field = this.$v.bus.model;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    yearError() {
      const errors = [];
      let field = this.$v.bus.year;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    colorError() {
      const errors = [];
      let field = this.$v.bus.color;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    seatsError() {
      const errors = [];
      let field = this.$v.bus.seats;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    floorError() {
      const errors = [];
      let field = this.$v.bus.floor;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    govermentNumberError() {
      const errors = [];
      let field = this.$v.bus.government_number;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    descriptionError() {
      const errors = [];
      let field = this.$v.bus.description;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
  },
};
</script>

<style scoped>
.optionBox {
  padding: 4px 8px;
  border-radius: 10px;
  background: rgba(20, 79, 169, 0.1);
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 20px;
  margin-top: 20px;
  cursor: pointer;
  user-select: none;
}
.activeOptionBox {
  color: #fafafa;
  background-color: #144fa9;
}
</style>