<template>
  <v-dialog v-model="visibility" width="720px">
    <v-card style="padding: 16px">
      <p class="sectionTitle">Нове місто</p>
      <div class="sectionLine" />
      <v-text-field
        outlined
        dense
        label="Назва сервісу"
        placeholder="Введіть тут"
        height="48px"
        style="border-radius: 10px"
        color="#E2E2E2"
        background-color="#FFF"
        v-model="name"
        :error-messages="nameError"
      />
      <v-text-field
        outlined
        dense
        label="Назва сервісу англійською"
        placeholder="Введіть тут"
        height="48px"
        style="border-radius: 10px"
        color="#E2E2E2"
        background-color="#FFF"
        v-model="name_en"
        :error-messages="nameENError"
      />
      <v-text-field
        outlined
        dense
        label="Назва сервісу польською"
        placeholder="Введіть тут"
        height="48px"
        style="border-radius: 10px"
        color="#E2E2E2"
        background-color="#FFF"
        v-model="name_pl"
        :error-messages="namePLError"
      />
      <submit-button
        style="
          width: 100%;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          height: 48px !important;
        "
        @click="createNewService"
        >Додати</submit-button
      >
      <v-row no-gutters justify="center" style="margin-top: 10px">
        <cancel-btn text="Скасувати" />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import modalMixin from "../../../mixins/modalMixin";
import CancelBtn from "../../UI/Buttons/cancelBtn.vue";
import submitButton from "../../UI/Buttons/submitButton.vue";
import { required } from "vuelidate/lib/validators";
import requestFormData from "../../../requests/requestFormData";
import busServicesService from "./../../../requests/Bus/busServicesService.js";
export default {
  components: { submitButton, CancelBtn },
  mixins: [modalMixin, validationMixin],
  data: () => ({
    name: "",
    name_en: "",
    name_pl: "",
  }),
  props: {
    order: {
      require: false,
    },
  },
  validations: {
    name: {
      required,
    },
    name_en: {
      required,
    },
    name_pl: {
      required,
    },
  },
  methods: {
    async createNewService() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let data = {
          order: this.order,
          translations: [
            {
              lang: "ua",
              name: this.name,
            },
            {
              lang: "en",
              name: this.name_en,
            },
            {
              lang: "pl",
              name: this.name_pl,
            },
          ],
        };
        let form = requestFormData.jsonToFormData(data);
        await busServicesService.createService(form).then((res) => {
          if (res.status == "Success") {
            this.$emit("successCreateService");
          }
        });
      }
    },
  },
  computed: {
    nameError() {
      const errors = [];
      let field = this.$v.name;
      if (!field.$dirty) {
        return errors;
      } else if (!field.required) {
        errors.push("");
      }
      return errors;
    },
    nameENError() {
      const errors = [];
      let field = this.$v.name_en;
      if (!field.$dirty) {
        return errors;
      } else if (!field.required) {
        errors.push("");
      }
      return errors;
    },
    namePLError() {
      const errors = [];
      let field = this.$v.name_pl;
      if (!field.$dirty) {
        return errors;
      } else if (!field.required) {
        errors.push("");
      }
      return errors;
    },
  },
};
</script>

<style scoped>
</style>