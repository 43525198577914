<template>
  <v-btn
    :disabled="disabled"
    text
    class="addNewItemBtn"
    @click="$emit('click')"
  >
    <v-icon color="#144FA9">mdi-plus</v-icon>
    <span>{{ text }}</span>
  </v-btn>
</template>

<script>
export default {
  props: {
    text: {
      require: true,
    },
    disabled: {
      require: true,
    },
  },
};
</script>

<style scoped>
.addNewItemBtn {
  color: #144fa9;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: none;
  padding: 8px !important;
}
</style>