<template>
  <div>
    <v-container>
      <success-snackbar
        v-if="showSuccessSnackbar"
        :snackbarText="snackbarText"
      />
      <v-row no-gutters align="start">
        <v-col cols="6" xl="6" lg="6" md="7" sm="12" class="d-flex">
          <div style="text-align: center; margin-right: 20px">
            <p class="type-seats-label">Місце</p>
            <v-row no-gutters justify="center">
              <div
                class="type-seats-box"
                :class="activeType == 'seats' ? 'type-seats-box-active' : ''"
                @click="activeType = 'seats'"
              >
                <img
                  width="42px"
                  height="42px"
                  src="@/assets/img/busConstructor/seat.svg"
                  alt="seats"
                />
              </div>
            </v-row>
          </div>
          <div style="text-align: center; margin-right: 20px">
            <p class="type-seats-label">Службове</p>
            <v-row no-gutters justify="center">
              <div
                class="type-seats-box"
                :class="activeType == 'service' ? 'type-seats-box-active' : ''"
                @click="activeType = 'service'"
              >
                <img
                  width="42px"
                  height="42px"
                  src="@/assets/img/busConstructor/service.svg"
                  alt="service"
                />
              </div>
            </v-row>
          </div>
          <div style="text-align: center; margin-right: 20px">
            <p class="type-seats-label">Вхід\Вихід</p>
            <v-row no-gutters justify="center">
              <div
                class="type-seats-box"
                :class="activeType == 'door' ? 'type-seats-box-active' : ''"
                @click="activeType = 'door'"
              >
                <img
                  width="42px"
                  height="42px"
                  src="@/assets/img/busConstructor/door.svg"
                  alt="door"
                />
              </div>
            </v-row>
          </div>
          <div style="text-align: center; margin-right: 20px">
            <p class="type-seats-label">Кермо</p>
            <v-row no-gutters justify="center">
              <div
                class="type-seats-box"
                :class="
                  activeType == 'handelbar' ? 'type-seats-box-active' : ''
                "
                @click="activeType = 'handelbar'"
              >
                <img
                  width="42px"
                  height="42px"
                  src="@/assets/img/busConstructor/handelbar.svg"
                  alt="handelbar"
                />
              </div>
            </v-row>
          </div>
          <div style="text-align: center; margin-right: 20px">
            <p class="type-seats-label">Прохід</p>
            <v-row no-gutters justify="center">
              <div
                class="type-seats-box"
                :class="activeType == 'aisle' ? 'type-seats-box-active' : ''"
                @click="activeType = 'aisle'"
              ></div>
            </v-row>
          </div>
        </v-col>
        <v-col
          cols="6"
          xl="6"
          lg="6"
          md="5"
          sm="12"
          class="px-0"
          style="margin-top: 12px"
        >
          <v-row no-gutters justify="end">
            <v-col class="px-0 py-0" md="10" lg="5" xl="3">
              <span
                style="
                  color: #1b1b1b;
                  font-family: 'MacPaw Fixel';
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                "
                >Ширина\Висота</span
              >
              <v-row no-gutters align="center" style="margin-top: 4px">
                <v-col cols="6" style="padding-right: 5px">
                  <v-text-field
                    outlined
                    dense
                    prepend-inner-icon="mdi-minus"
                    append-icon="mdi-plus"
                    v-model="cols_quantity"
                    readonly
                    @click:prepend-inner="decreaseCols"
                    @click:append="increaseCols"
                    class="text-center"
                    style="border-radius: 10px"
                  />
                </v-col>
                <v-col cols="6" style="padding-left: 5px">
                  <v-text-field
                    outlined
                    dense
                    prepend-inner-icon="mdi-minus"
                    append-icon="mdi-plus"
                    v-model="rows_quantity"
                    readonly
                    @click:prepend-inner="decreaseRows"
                    @click:append="increaseRows"
                    class="text-center"
                    style="border-radius: 10px"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-col
        style="margin-top: 12px; margin-bottom: 20px"
        class="px-0 py-0"
        :key="key"
      >
        <div style="width: max-content; max-width: 100%; overflow-x: scroll">
          <div
            v-for="(row, rowIndex) in seats"
            :key="rowIndex"
            style="display: flex; white-space: nowrap; width: max-content"
          >
            <div
              v-for="(seat, seatIndex) in row"
              :key="seatIndex"
              class="seat"
              @click="
                seat.type !== activeType ? setSeatType(rowIndex, seat.id) : ''
              "
              @dblclick="seat.type == 'seats' ? showEditNumberModal(seat) : ''"
              :draggable="true"
              @dragstart="onDragStart(rowIndex, seatIndex)"
              @dragover.prevent
              @drop="onDrop(rowIndex, seatIndex)"
            >
              <span
                v-if="seat.type !== 'seat'"
                style="position: absolute; color: white"
              >
                {{ seat.number }}
              </span>
              <img
                width="42px"
                height="42px"
                v-if="seat.type !== 'aisle'"
                :src="getSeatImage(seat?.type)"
                :alt="seat.type"
              />
              <span v-else></span>
            </div>
          </div>
        </div>
      </v-col>
      <v-row no-gutters align="center" justify="end">
        <submit-button
          @click="setSeatsScheme"
          style="width: 160px; height: 48px; margin-right: 20px"
          >Зберегти</submit-button
        >
        <submit-button
          style="
            width: 160px;
            height: 48px;
            margin-right: 20px;
            background-color: #e7edf6 !important;
            color: #1b1b1b !important;
          "
          @click="showSaveModal = true"
          >Зберегти шаблон</submit-button
        >
        <cancel-btn text="Скасувати" @click="$emit('cancel')"></cancel-btn>
      </v-row>
    </v-container>
    <save-bus-scheme-modal
      v-if="showSaveModal"
      :visible="showSaveModal"
      @confirmSave="saveScheme"
      @close="showSaveModal = false"
    />
    <edit-number-modal
      v-if="isShowEditNumberModal"
      :visible="isShowEditNumberModal"
      :seatForEdit="seatForEdit"
      :seats="seats"
      @confirmSave="saveNewNumber"
      @close="isShowEditNumberModal = false"
    />
  </div>
</template>
    
    <script>
import busSchemeService from "@/requests/Bus/busSchemeService";
import requestFormData from "@/requests/requestFormData";
import CancelBtn from "../../../UI/Buttons/cancelBtn.vue";
import submitButton from "../../../UI/Buttons/submitButton.vue";
import SuccessSnackbar from "../../../UI/Snackbars/successSnackbar.vue";
import SaveBusSchemeModal from "./saveBusSchemeModal.vue";
import EditNumberModal from "./editNumberModal.vue";
export default {
  components: {
    submitButton,
    CancelBtn,
    SaveBusSchemeModal,
    SuccessSnackbar,
    EditNumberModal,
  },
  data() {
    return {
      seatTypes: [
        {
          value: "seats",
          label: "Місце",
          icon: "seat.svg",
        },
        {
          value: "service",
          label: "Службове",
          icon: "service.svg",
        },
        {
          value: "door",
          label: "Вхід\\Вихід",
          icon: "door.svg",
        },
        {
          value: "handelbar",
          label: "Кермо",
          icon: "handelbar.svg",
        },
        { value: "aisle", label: "Прохід", icon: "" },
      ],
      rows_quantity: 5,
      cols_quantity: 16,
      activeType: "aisle",
      seats: [],
      rows: [],
      cols: [],
      key: 0,
      draggedSeat: null,
      originalPosition: { x: null, y: null },
      draggableOptions: {
        dropzoneSelector: "v-row",
        draggableSelector: "v-col",
        handlerSelector: null,
        reactivityEnabled: true,
        multipleDropzonesItemsDraggingEnabled: true,
        showDropzoneAreas: true,
      },
      draggedItem: null,
      draggedGroupIndex: null,
      draggedItemIndex: null,
      showSaveModal: false,
      showSuccessSnackbar: false,
      isShowEditNumberModal: false,
      snackbarText: "",
      seatForEdit: {},
    };
  },
  props: {
    choosedScheme: {
      require: false,
    },
    busScheme: {
      require: false,
    },
    isNewScheme: {
      require: true,
    },
  },
  mounted() {
    if (this.isNewScheme) {
      this.initializeSeats();
    } else if (Object.values(this.busScheme).length > 0) {
      this.setSchemeByBus();
    } else if (
      this.choosedScheme &&
      Object.values(this.choosedScheme).length > 0
    ) {
      this.setSchemeByChoose();
    } else {
      this.initializeSeats();
    }
  },
  methods: {
    setSchemeByBus() {
      this.seats = this.busScheme.scheme;
      this.rows_quantity = this.busScheme.height;
      this.cols_quantity = this.busScheme.width;
      this.key++;
    },
    setSchemeByChoose() {
      this.seats = JSON.parse(this.choosedScheme.schema);
      this.rows_quantity = parseInt(this.choosedScheme.height);
      this.cols_quantity = parseInt(this.choosedScheme.width);
      this.key++;
    },
    showEditNumberModal(seat) {
      this.seatForEdit = seat;
      this.isShowEditNumberModal = true;
    },
    saveNewNumber(seat) {
      this.seats[seat.y][seat.x] = seat;
      this.isShowEditNumberModal = false;
    },
    async saveScheme(name) {
      let data = {
        name: name,
        scheme: {
          width: this.cols_quantity,
          height: this.rows_quantity,
          schema: JSON.stringify(this.seats),
        },
      };
      let form = requestFormData.jsonToFormData(data);
      this.showSaveModal = false;
      await busSchemeService.createScheme(form).then((res) => {
        if (res.status == "Success") {
          this.showSuccessSnackbar = true;
          this.snackbarText = "Схема успішно створена";
          this.$emit("successCreateScheme");
          setTimeout(() => {
            this.showSuccessSnackbar = false;
          }, 3000);
        }
      });
    },
    setSeatsScheme() {
      this.$emit("setSeatsScheme", {
        width: this.cols_quantity,
        height: this.rows_quantity,
        scheme: this.seats,
      });
    },
    initializeSeats() {
      this.rows = Array(this.rows_quantity).fill(null);
      this.cols = Array(this.cols_quantity).fill(null);
      this.seats = Array(this.rows_quantity)
        .fill()
        .map((_, rowIndex) =>
          Array(this.cols_quantity)
            .fill(null)
            .map((_, colIndex) => ({
              id: crypto.randomUUID(),
              type: "aisle",
              number: null,
              for_sell: false,
              is_sell: false,
              is_booking: false,
              x: colIndex,
              y: rowIndex,
            }))
        );
      this.key++;
    },
    getSeatImage(type) {
      switch (type) {
        case "seats":
          return require("@/assets/img/busConstructor/seat.svg");
        case "service":
          return require("@/assets/img/busConstructor/service.svg");
        case "door":
          return require("@/assets/img/busConstructor/door.svg");
        case "handelbar":
          return require("@/assets/img/busConstructor/handelbar.svg");
        default:
          return "";
      }
    },
    setSeatType(row, id) {
      const index = this.seats[row].findIndex((seat) => seat.id == id);
      if (this.activeType === "seats") {
        const maxSeatNumber = this.seats
          .flat()
          .filter((seat) => seat.type === "seats" && seat.number != null)
          .reduce((max, seat) => Math.max(max, seat.number), 0);
        this.seats[row][index].type = this.activeType;
        this.seats[row][index].number = maxSeatNumber + 1;
      } else {
        this.seats[row][index].type = this.activeType;
        this.seats[row][index].number = null;
      }
      this.key++;
    },
    increaseCols() {
      this.cols_quantity = Math.min(this.cols_quantity + 1, 25);
    },
    decreaseCols() {
      this.cols_quantity = Math.max(this.cols_quantity - 1, 1);
    },
    increaseRows() {
      this.rows_quantity = Math.min(this.rows_quantity + 1, 20);
    },
    decreaseRows() {
      this.rows_quantity = Math.max(this.rows_quantity - 1, 1);
    },
    onDragStart(groupIndex, itemIndex) {
      // Store information about the dragged item
      this.draggedItem = this.seats[groupIndex][itemIndex];
      this.draggedGroupIndex = groupIndex;
      this.draggedItemIndex = itemIndex;
    },

    onDragEnd() {
      // Reset variables after the drag operation ends
      this.draggedItem = null;
      this.draggedGroupIndex = null;
      this.draggedItemIndex = null;
    },

    onDrop(targetGroupIndex, targetItemIndex) {
      if (this.draggedItem) {
        // Get the dragged item
        const draggedItem =
          this.seats[this.draggedGroupIndex][this.draggedItemIndex];
        const targetItem = this.seats[targetGroupIndex][targetItemIndex];

        // Swap the items regardless of group (row) or position
        this.$set(this.seats[targetGroupIndex], targetItemIndex, draggedItem);
        this.$set(
          this.seats[this.draggedGroupIndex],
          this.draggedItemIndex,
          targetItem
        );

        // Reset the variables after the swap is complete
        this.onDragEnd();
      }
    },
  },
  watch: {
    cols_quantity(newCols) {
      this.seats.forEach((row, rowIndex) => {
        if (newCols > row.length) {
          row.push(
            ...Array(newCols - row.length)
              .fill(null)
              .map((_, colIndex) => ({
                id: crypto.randomUUID(),
                type: "aisle",
                number: null,
                for_sell: false,
                is_sell: false,
                is_booking: false,
                x: row.length + colIndex,
                y: rowIndex,
              }))
          );
        } else {
          row.splice(newCols);
        }
      });
      this.cols = Array(newCols).fill(null);
    },
    rows_quantity(newRows) {
      if (newRows > this.seats.length) {
        this.seats.push(
          ...Array(newRows - this.seats.length)
            .fill()
            .map((_, rowIndex) =>
              Array(this.cols_quantity)
                .fill(null)
                .map((_, colIndex) => ({
                  id: crypto.randomUUID(),
                  type: "aisle",
                  number: null,
                  for_sell: false,
                  is_sell: false,
                  is_booking: false,
                  x: colIndex,
                  y: this.seats.length + rowIndex,
                }))
            )
        );
      } else {
        this.seats.splice(newRows);
      }
      this.rows = Array(newRows).fill(null);
    },
    choosedScheme: {
      deep: true,
      handler() {
        this.setSchemeByChoose();
      },
    },
    busScheme: {
      deep: true,
      handler() {
        this.setSchemeByBus();
      },
    },
  },
};
</script>
    
    <style scoped>
.seat {
  border: 1px solid silver;
  width: 48px;
  height: 48px;
  display: grid;
  place-items: center;
}
.seat-aisle {
  background-color: white;
  border: 1px solid silver;
}

.type-seats-label {
  margin-bottom: 10px;
  color: var(--Grey2, #4b5262);
  font-family: "MacPaw Fixel";
  font-size: 16px;
}

.type-seats-box {
  width: 48px;
  height: 48px;
  display: grid;
  place-items: center;
  border: 1px dashed #989898;
  cursor: pointer;
}
.type-seats-box:hover {
  border: 2px solid #144fa9;
  border-radius: 5px;
}
.type-seats-box-active {
  border: 2px solid #144fa9;
  border-radius: 5px;
}
</style>
  <style>
.text-center .v-input__control .v-input__slot input {
  text-align: center;
}
</style>
    