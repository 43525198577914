<template>
  <div
    class="imageBackground"
    @mousemove="isImageHover = true"
    @mouseleave="isImageHover = false"
  >
    <div
      class="backgroundIcon"
      @click="deleteImg(image.id)"
      v-if="image.path !== '' && isImageHover"
      style="position: absolute"
    >
      <div class="deleteIcon" />
    </div>
    <img
      v-if="image.path !== ''"
      :src="image.path"
      alt="image"
      width="100%"
      height="100%"
      style="object-fit: cover"
    />
    <v-col
      style="text-align: center"
      v-else-if="image.path == ''"
      @click="$emit('click')"
    >
      <img src="@/assets/img/iconsSvg/imageIcon.svg" />
      <p
        style="
          color: #1b1b1b;
          font-family: 'MacPaw Fixel';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-decoration: underline;
          margin-top: 10px;
        "
      >
        Завантажити фото
      </p>
    </v-col>
    <input
      type="file"
      ref="image_input"
      style="display: none"
      @change="uploadImg"
      accept="image/png, image/jpeg, image/jpg"
    />
  </div>
</template>
  
  <script>
export default {
  data: () => ({
    isImageHover: false,
    addImageIndex: -1,
  }),
  props: {
    image: {
      require: true,
    },
    index: {
      require: true,
    },
  },
  methods: {
    addNewImage() {
      this.$emit("showModal");
    },
    uploadImg(e) {
      let file = e.srcElement.files[0];
      var reader = new FileReader();
      let that = this;
      reader.readAsDataURL(file);
      reader.onload = function () {
        setTimeout(() => {
          that.$emit("uploadImg", that.addImageIndex, reader.result, file);
        }, 100);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },
    deleteImg(id) {
      this.$emit("deleteImg", id);
    },
  },
};
</script>
  
  <style scoped>
.imageBackground {
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  background: #e7edf6;
  width: 200px;
  height: 200px;
  display: grid;
  place-items: center;
  cursor: pointer;
  user-select: none;
  margin-right: 20px;
}
.addImageText {
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: underline;
  margin-top: 10px;
}
.backgroundIcon .imageBackground:hover {
  background: transparent !important;
}
.backgroundIcon {
  background: #eeeeee;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}
.backgroundIcon:hover .deleteIcon {
  background-color: #ca0000;
}
.deleteIcon {
  width: 19px;
  height: 24px;
  background-color: #1b1b1b;
  -webkit-mask: url("../../../assets/img/iconsSvg/deleteIcon.svg") no-repeat
    center;
  mask: url("../../../assets/img/iconsSvg/deleteIcon.svg") no-repeat center;
}
.deleteIcon:hover {
  background-color: #ca0000;
}
.addImageText:hover {
  color: #149e51;
}
</style>