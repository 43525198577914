<template>
  <v-row
    no-gutters
    align="center"
    style="margin: 12px 0px"
    @click="$emit('click')"
  >
    <v-icon color="#1B1B1B" class="pointer" @click="$emit('back')"
      >mdi-chevron-left</v-icon
    >
    <span class="backLabel pointer" @click="$emit('back')">{{ label }}</span>
  </v-row>
</template>
  
  <script>
export default {
  props: {
    label: {
      require: true,
    },
  },
};
</script>
  
  <style scoped>
.backLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.1em;
  color: #1b1b1b;
  margin-left: 10px;
}
</style>